__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import {onLoading} from "./_modules/website";
import {loadPage} from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import './_modules/swiper';


document.addEventListener("DOMContentLoaded", () => {
    onLoading();
    loadPage();


    const logo = document.querySelector(".site-logo");
    const overlay = document.querySelector('.overlay');

    logo.addEventListener("click", () => {

        overlay.classList.toggle('in-view');

    });


    const moreBtn = document.getElementById('more');
    const slideTextContent = document.querySelectorAll('.the_content');


    moreBtn.addEventListener('click', () => {
        moreBtn.classList.toggle('closed');
        slideTextContent.forEach((elem) =>{
           elem.classList.toggle('closed');
        });
    });


});