import Swiper, {
    Autoplay,
    EffectFade,
    FreeMode,
    HashNavigation,
    Keyboard,
    Lazy,
    Manipulation,
    Mousewheel,
    Navigation
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';


export const Carousel = () => {

    const slider = document.querySelector(".swiper-container");
    if (slider) {
        const swiperH = new Swiper('.swiper-container', {
            modules: [Navigation, Manipulation, EffectFade, Mousewheel, Autoplay, Keyboard, HashNavigation],
            cssMode: false,
            direction: 'horizontal',
            followFinger: true,
            preloadImages: false,
            allowTouchMove: true,
            hashNavigation: {
                replaceState: true,
                watchState: true,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: false,
            },
            watchSlidesProgress: true,
            speed: 300,
            on: {
                init: function () {
                },
               /* activeIndexChange: function (){
                    const paginationEl = document.querySelector('.pagination .active-index')
                    paginationEl.innerHTML = this.realIndex + 1;
                }*/

            }
        });
    }
}


Carousel();